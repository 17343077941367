@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
@import "mixins";
@import "variables";

body {
  font-family: $gl-font-family-base;
  --toastify-toast-width: 500px;
  background-color: $clr-background-light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700&display=swap");

html,
body,
#__next {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

#__next {
  overflow: auto;
}

html {
  font-size: 100%;
}

body {
  font-size: initial;
  line-height: 150%;
}

// Icons
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-lg {
  vertical-align: -0.225em;
}
.svg-inline--fa.fa-w-1 {
  width: 0.0625em;
}
.svg-inline--fa.fa-w-2 {
  width: 0.125em;
}
.svg-inline--fa.fa-w-3 {
  width: 0.1875em;
}
.svg-inline--fa.fa-w-4 {
  width: 0.25em;
}
.svg-inline--fa.fa-w-5 {
  width: 0.3125em;
}
.svg-inline--fa.fa-w-6 {
  width: 0.375em;
}
.svg-inline--fa.fa-w-7 {
  width: 0.4375em;
}
.svg-inline--fa.fa-w-8 {
  width: 0.5em;
}
.svg-inline--fa.fa-w-9 {
  width: 0.5625em;
}
.svg-inline--fa.fa-w-10 {
  width: 0.625em;
}
.svg-inline--fa.fa-w-11 {
  width: 0.6875em;
}
.svg-inline--fa.fa-w-12 {
  width: 0.75em;
}
.svg-inline--fa.fa-w-13 {
  width: 0.8125em;
}
.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}
.svg-inline--fa.fa-w-15 {
  width: 0.9375em;
}
.svg-inline--fa.fa-w-16 {
  width: 1em;
}
.svg-inline--fa.fa-w-17 {
  width: 1.0625em;
}
.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}
.svg-inline--fa.fa-w-19 {
  width: 1.1875em;
}
.svg-inline--fa.fa-w-20 {
  width: 1.25em;
}
.svg-inline--fa.fa-pull-left {
  margin-right: 0.3em;
  width: auto;
}
.svg-inline--fa.fa-pull-right {
  margin-left: 0.3em;
  width: auto;
}
.svg-inline--fa.fa-border {
  height: 1.5em;
}
.svg-inline--fa.fa-li {
  width: 2em;
}
.svg-inline--fa.fa-fw {
  width: 1.25em;
}

// Replace when groove container ID has been changed.
#groove-container-22f64306-3ad3-4c15-919f-6b25abdd7275 {
  z-index: $gl-z-index-groove !important;
}

#groove-container-f07f6f5c-592a-4532-8241-3f87c96c6938 {
  z-index: $gl-z-index-groove !important;
}

// Replace when new groove container ID has been added.
// #groove-container-22f64306-3ad3-4c15-919f-6b25abdd7275 {
//   z-index: $gl-z-index-groove !important;
// }

// Make navigation bar for light sticky to be consistent with default.
.section.navigation-bar {
  position: sticky;
  top: 0px;
  z-index: $gl-z-index-1 - 1;
}

.icon-android {
  color: $clr-icon-android;
}

.icon-ios {
  color: $clr-icon-ios;
}

.gn-block {
  display: block;
}

.gn-hidden {
  display: none;
}

.gn-block-desktop {
  @media (min-width: $gl-breakpoint-mobile + 1) {
    display: block;
  }
}

.gn-flip {
  transform: scale(-1, 1);
}

.gn-hidden-desktop {
  @media (min-width: $gl-breakpoint-mobile + 1) {
    display: none;
  }
}

.Toastify__toast-container--bottom-center {
  max-width: 740px !important;
  width: 100% !important;
}

.Toastify__toast-container--bottom-center .Toastify__toast-theme--light {
  background: #f8f9fa !important;
  border: 1px solid #dee2e6;
}

#current-organization-name,
.text-block-21 {
  max-width: 236px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap;
}

#sign-in-button-container {
  margin-left: 1rem;
}
